import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';

const isTest = new URLSearchParams(window.location.search).get('test') !== null;
const isDevelopment = window.location.hostname === 'localhost';

const STRIPE_CONFIG = {
    pricingTableId: isDevelopment
        ? 'prctbl_1QXrSMCOeGkKGwWR7WQOwdWX'
        : isTest
            ? 'prctbl_1QXxd3COeGkKGwWRNdjNUKz4'  // test table in production
            : 'prctbl_1QXxFrCOeGkKGwWReEC9V3cX',  // regular production table
    publishableKey: isDevelopment
        ? 'pk_test_51QWnonCOeGkKGwWRcxW4tUZsWqCIkgTRXYkavFUewUXAgBKoYKZTmyPctMqp4Q6ZFOEDCyHFjaOT5I5ndu9QXd8100jZXadxYR'
        : 'pk_live_51QWnonCOeGkKGwWRctH6awIeOO08BnpM5L4dploGMNg7ShFAotmRBOaVVr8oQUJNdFIUSsIoW5t9WsYTUt3JTtrP00ZkzPiGmP'  // same live key for both
};

const Account = () => {
    const { user } = useContext(AuthContext);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccess, setPasswordSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);

    useEffect(() => {
        // Load Stripe Pricing Table script
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/pricing-table.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const fetchSubscription = async () => {
            try {
                setIsLoadingSubscription(true);
                const response = await fetch('/api/subscriptions/current', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch subscription');
                }

                const data = await response.json();
                setSubscription(data);
            } catch (error) {
                console.error('Error fetching subscription:', error);
            } finally {
                setIsLoadingSubscription(false);
            }
        };

        fetchSubscription();
    }, []);

    const handlePasswordChange = async () => {
        setPasswordError('');
        setPasswordSuccess('');

        if (newPassword !== confirmPassword) {
            setPasswordError('New passwords do not match');
            return;
        }

        if (newPassword.length < 8) {
            setPasswordError('New password must be at least 8 characters long');
            return;
        }

        setIsSubmitting(true);

        try {
            const response = await fetch(`/api/users/${user.id}/update_password`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    current_password: currentPassword,
                    new_password: newPassword,
                    new_password_confirmation: confirmPassword
                })
            });

            const data = await response.json();

            if (!response.ok) {
                setPasswordError(data.error || data.errors?.join(', ') || 'Failed to update password');
                return;
            }

            setCurrentPassword('');
            setNewPassword('');
            setConfirmPassword('');
            setPasswordSuccess('Password updated successfully');
        } catch (error) {
            console.error('Error updating password:', error);
            setPasswordError('An error occurred while updating password');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderSubscriptionStatus = () => {
        if (subscription?.status === 'active') {
            return (
                <div className="space-y-4">
                    <div className="p-4 bg-gray-50 rounded-lg">
                        <h3 className="text-lg font-medium text-gray-900 mb-2">Current Subscription</h3>
                        <div className="space-y-2">
                            <p className="text-sm text-gray-700">
                                <span className="font-medium">Plan:</span> Premier Plan
                            </p>
                            <p className="text-sm text-gray-700">
                                <span className="font-medium">Status:</span>
                                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Active
                                </span>
                            </p>
                            {subscription.current_period_end && (
                                <p className="text-sm text-gray-700">
                                    <span className="font-medium">Current Period Ends:</span>{' '}
                                    {new Date(subscription.current_period_end).toLocaleDateString()}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        if (isLoadingSubscription) {
            return (
                <div className="flex items-center justify-center py-6">
                    <div className="w-6 h-6 border-2 border-gray-300 border-t-blue-600 rounded-full animate-spin"></div>
                </div>
            );
        }

        return !subscription?.status || subscription.status !== 'active' ? (
            <stripe-pricing-table
                pricing-table-id={STRIPE_CONFIG.pricingTableId}
                publishable-key={STRIPE_CONFIG.publishableKey}
                client-reference-id={user.id}>
            </stripe-pricing-table>
        ) : null;
    };

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <div className="max-w-4xl mx-auto px-4">
                <div className="flex items-center mb-8">
                    <h1 className="text-3xl font-bold text-gray-900">Account Settings</h1>
                </div>

                {/* Profile Section */}
                <div className="bg-white rounded-lg shadow mb-8 p-6">
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-900 mb-1">Profile Information</h2>
                        <p className="text-sm text-gray-500">Your account details</p>
                    </div>
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Email Address
                            </label>
                            <input
                                type="email"
                                value={user?.email || 'Loading...'}
                                readOnly
                                disabled
                                className="w-full max-w-md px-3 py-2 bg-gray-50 border border-gray-300 rounded-md text-gray-600"
                            />
                            <p className="mt-1 text-sm text-gray-500">Contact support to update your email address</p>
                        </div>
                    </div>
                </div>

                {/* Password Section */}
                <div className="bg-white rounded-lg shadow mb-8 p-6">
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-900 mb-1">Change Password</h2>
                        <p className="text-sm text-gray-500">Update your account password</p>
                    </div>

                    {passwordError && (
                        <div className="mb-4 bg-red-100 text-red-700 px-4 py-3 rounded">
                            {passwordError}
                        </div>
                    )}

                    {passwordSuccess && (
                        <div className="mb-4 bg-green-100 text-green-700 px-4 py-3 rounded">
                            {passwordSuccess}
                        </div>
                    )}

                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Current Password
                            </label>
                            <input
                                type="password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                className="w-full max-w-md px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none focus:border-transparent transition-colors"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                New Password
                            </label>
                            <input
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                className="w-full max-w-md px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none focus:border-transparent transition-colors"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Confirm New Password
                            </label>
                            <input
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="w-full max-w-md px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none focus:border-transparent transition-colors"
                            />
                        </div>
                        <div>
                            <button
                                onClick={handlePasswordChange}
                                disabled={isSubmitting}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed transition-colors flex items-center"
                            >
                                {isSubmitting ? (
                                    <>
                                        <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2" />
                                        Processing...
                                    </>
                                ) : (
                                    'Update Password'
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Subscription Section */}
                <div className="bg-white rounded-lg shadow p-6">
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-900 mb-1">Billing</h2>
                        <p className="text-sm text-gray-500">Manage your subscription</p>
                    </div>
                    {renderSubscriptionStatus()}
                </div>
            </div>
        </div>
    );
};

export default Account;